/**
 * @module M/control/ShareMapControl
 */
import template from '../../templates/sharemap';
import modal from '../../templates/modal';
import { getValue } from './i18n/language';

/**
 * This function adds the animation shade class.
 *
 * @function
 * @private
 */
const beginShade = (element) => {
  if (element.classList.contains('m-plugin-sharemap-shade')) {
    element.classList.remove('m-plugin-sharemap-shade');
  } else {
    element.classList.add('m-plugin-sharemap-shade');
    setTimeout(() => {
      element.classList.remove('m-plugin-sharemap-shade');
    }, 2000); // animationDuration 2s
  }
};

/**
 * This function copies the textr input in clipboard
 *
 * @function
 * @private
 */
const copyURL = (input) => {
  const inputVar = input;
  inputVar.disabled = false;
  inputVar.select();
  document.execCommand('copy');
  inputVar.disabled = true;
  document.getSelection().removeAllRanges();
};

/**
 * @classdesc
 * ShareMap mapea control.
 * This control allows current map state sharing via URL.
 */
export default class ShareMapControl extends M.Control {
  /**
   * @constructor
   * @extends {M.Control}
   * @api stable
   */
  constructor(options) {
    const impl = new M.impl.Control();
    super(impl, 'sharemap');
    /**
     * Base url of the shared map
     *
     * @private
     * @type {URLLike}
     */
    this.baseUrl_ = `${window.location.protocol}//${window.location.href.split('/')[2]}/fototeca`;
  }

  /**
   * This function creates the view
   *
   * @public
   * @function
   * @param {M.Map} map to add the control
   * @api
   */
  createView(map) {
    this.map_ = map;
    return new Promise((success, fail) => {
      const html = M.template.compileSync(template);
      const button = html.querySelector('button');
      button.setAttribute('aria-label', 'Plugin Sharemap');
      this.addEvents(html);
      success(html);
    });
  }

  /**
   * This function adds the event to the view html
   *
   * @public
   * @param html - HTML template of the view
   * @api
   */
  addEvents(html) {
    html.querySelector('#m-sharemap-geturl').addEventListener('click', () => {
      if (!document.querySelector('#m-plugin-sharemap-title')) {
        this.activateModal();
      }
    });
  }

  /**
   * This function adds modal view to html
   *
   * @public
   * @param html - HTML template of the view
   * @api
   */
  activateModal() {
    const dialog = M.template.compileSync(modal, {
      vars: {
        title: getValue('title'),
        text: getValue('text'),
        btn: getValue('close'),
        copyBtn: getValue('copy'),
        copyBtnHtml: getValue('copy'),
        tooltip: getValue('tooltip'),
      },
    });

    const message = dialog.querySelector('#m-plugin-sharemap-message input');
    const html = dialog.querySelector('#m-plugin-sharemap-html input');
    const mapeaContainer = document.querySelector('div.m-mapea-container');
    const okButton = dialog.querySelector('#m-plugin-sharemap-button > button');
    okButton.addEventListener('click', () => {
      this.removeElement(dialog);
      document.querySelector('.m-sharemap-container').click();
      document.querySelector('#m-sharemap-geturl').focus();
    });

    const copyButton = dialog.querySelector('#m-plugin-sharemap-copybutton');
    const copyButtonHtml = dialog.querySelector('#m-plugin-sharemap-copybuttonhtml');
    const title = dialog.querySelector('#m-plugin-sharemap-title');
    copyButton.addEventListener('click', () => {
      copyURL(message);
      beginShade(title.querySelector('#m-plugin-sharemap-tooltip'));
    });

    copyButtonHtml.addEventListener('click', () => {
      copyURL(html);
      beginShade(title.querySelector('#m-plugin-sharemap-tooltip'));
    });

    this.buildURLComplete(dialog);
    mapeaContainer.appendChild(dialog);
    this.buildHtml(dialog);
    mapeaContainer.appendChild(dialog);
    title.focus();
    title.click();
    document.addEventListener('keyup', this.checkEscKey.bind(this, dialog));
  }

  /**
   * This function removes the html element from DOM.
   *
   * @function
   * @private
   */
  removeElement(element) {
    document.removeEventListener('keyup', this.checkEscKey.bind(this, element));
    const parent = element.parentElement;
    if (parent !== null) {
      parent.removeChild(element);
    }
  }

  checkEscKey(dialog, evt) {
    if (evt.key === 'Escape') {
      this.removeElement(dialog);
      document.querySelector('.m-sharemap-container').click();
      document.querySelector('#m-sharemap-geturl').focus();
    }
  }

  /**
   * This method builds the url to sharing.
   *
   * @public
   * @function
   */
  buildURL() {
    const { x, y } = this.map_.getCenter();
    let shareURL = `${this.baseUrl_}?center=${x},${y}&zoom=${this.map_.getZoom()}&srs=${this.map_.getProjection().code}`;
    const filteredLayers = this.map_.getLayers().filter(l => l.isBase === false && (['WMS', 'WMTS'].indexOf(l.type) > -1) && l.isVisible());
    const layers = filteredLayers.map(fl => fl.name.replaceAll(',', '*'));
    shareURL = layers.length > 0 ? shareURL.concat(`&layers=${layers.join(',')}`) : shareURL.concat('');
    shareURL = shareURL.concat(this.getPhotogram());
    const filteredPlugins = this.map_.getPlugins().filter(p => p.name === 'backimglayer');
    if (filteredPlugins.length > 0) {
      shareURL = shareURL.concat(`&baseLayer=${filteredPlugins[0].layerId}`);
    }

    return shareURL;
  }

  /**
   * This method builds the url to sharing.
   *
   * @public
   * @function
   */
  buildURLComplete(html) {
    const input = html.querySelector('input');
    const twitter = html.querySelector('#twitter');
    const facebook = html.querySelector('#facebook');
    const pinterest = html.querySelector('#pinterest');
    const shareURL = encodeURI(this.buildURL());
    input.value = shareURL;
    M.remote.get(`http://tinyurl.com/api-create.php?url=${shareURL}`).then((response) => {
      facebook.href = `http://www.facebook.com/sharer.php?u=${response.text}`;
      twitter.href = `https://twitter.com/intent/tweet?url=${response.text}`;
      pinterest.href = `https://www.pinterest.es/pin/create/button/?url=${response.text}`;
    });
  }

  /**
   * This method builds the html.
   *
   * @public
   * @function
   */
  buildHtml(dialog) {
    const html = dialog.querySelector('#m-plugin-sharemap-html');
    const input = html.querySelector('input');
    const shareURL = this.buildURL();
    const embeddedHtml = `<iframe width="800" height="600" frameborder="0" style="border:0" src="${shareURL}"></iframe>`;
    input.value = shareURL;
    input.value = embeddedHtml;
  }

  getPhotogram() {
    let res = '';
    const filtered = this.map_.getMapImpl().getLayers().getArray().filter((layer) => {
      let filter = false;
      if (layer.getSource() !== null) {
        if (typeof layer.getSource().getUrl === 'function') {
          if (typeof layer.getSource().getParams === 'function') {
            if (layer.getSource().getParams().IMAGEN !== undefined) {
              filter = true;
            }
          }
        }
      }

      return filter;
    });

    if (filtered.length > 0) {
      const paths = [];
      filtered.forEach((f) => {
        paths.push(f.getSource().getParams().IMAGEN.replace('/var/www/apps/fototeca/data', ''));
      });

      res = `&photogram=${paths.join(',')}`;
    }

    return res;
  }

  /**
   * This function compares controls
   *
   * @public
   * @function
   * @param {M.Control} control to compare
   * @api stable
   */
  equals(control) {
    return control instanceof ShareMapControl;
  }

  normalizeString(text) {
    let newText = text.replace(/,/g, '');
    newText = newText.replace(/\*/g, '');
    return newText;
  }
}
