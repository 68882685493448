/**
 * @module M/plugin/ShareMap
 */

import '../assets/css/sharemap';
import ShareMapControl from './sharemapcontrol';
import { getValue } from './i18n/language';

import es from './i18n/es';
import en from './i18n/en';

/**
 * @typedef {Object} ShareMapOptions
 * @property {enum<string>} [position='BR']? Position of the view plugin.
 * Allowed values: 'BR' | 'TR' | 'BL' | 'TL'.
 */

/**
 * ShareMap plugin
 * @classdesc
 */
export default class ShareMap extends M.Plugin {
  /**
   * @constructor
   * @extends {M.Plugin}
   * @param {ShareMapOptions} options
   * @api
   */
  constructor(options = {}) {
    super();

    /**
     * Facade of the map
     * @private
     * @type {M.Map}
     */
    this.map_ = null;

    /**
     * Array of controls
     * @private
     * @type {Array<M.Control>}
     */
    this.controls_ = [];

    /**
     * Position of the plugin
     *
     * @private
     * @type {Enum} TL | TR | BL | BR
     */
    this.position_ = options.position || 'BR';

    /**
     * Plugin parameters
     * @public
     * @type {object}
     */
    this.options = options;
  }

  /**
   * Return plugin language
   *
   * @public
   * @function
   * @param {string} lang type language
   * @api stable
   */
  static getJSONTranslations(lang) {
    if (lang === 'en' || lang === 'es') {
      return (lang === 'en') ? en : es;
    }
    return M.language.getTranslation(lang).sharemap;
  }

  /**
   * This function adds this plugin into the map.
   *
   * @public
   * @function
   * @param {M.Map} map the map to add the plugin
   * @api
   */
  addTo(map) {
    this.control = new ShareMapControl({});
    this.controls_.push(this.control);
    this.map_ = map;
    this.panel_ = new M.ui.Panel('ShareMap', {
      collapsible: false,
      position: M.ui.position[this.position_],
      className: 'm-plugin-sharemap',
      tooltip: getValue('tooltipPanel'),
    });

    this.panel_.addControls(this.controls_);
    map.addPanels(this.panel_);
  }
  /**
   * This function destroys this plugin
   *
   * @public
   * @function
   * @api
   */
  destroy() {
    this.map_.removeControls([this.control]);
    [this.map_, this.control, this.controls_,
      this.panel_, this.position_] = [null, null, null, null, null];
  }

  /**
   * This functions returns the controls of the plugin.
   *
   * @public
   * @return {M.Control}
   * @api
   */
  getControls() {
    return this.controls_;
  }

  /**
   * Name of the plugin
   *
   * @getter
   * @function
   */
  get name() {
    return 'sharemap';
  }

  /**
   * This function returns the facade map.
   *
   * @public
   * @return {M.Map}
   * @api
   */
  get map() {
    return this.map_;
  }

  /**
   * This function returns the position
   *
   * @public
   * @return {string}
   * @api
   */
  get position() {
    return this.position_;
  }

  /**
   * Get the API REST Parameters of the plugin
   *
   * @function
   * @public
   * @api
   */
  getAPIRest() {
    return `${this.name}=${this.position}`;
  }
}
